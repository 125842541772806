import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo";

interface Props {}

const SublimacePage = (props: Props) => {
  return (
    <Layout>
      <SEO title="Sublimace" />
      <h1>Sublimace</h1>
      <div className="z-10 relative">
        <p className="mb-3 text-justify">
          Etikety výborné kvality, téměř neomezených barevných možností a
          nepřekonatelné trvanlivosti nabízí metoda sublimace.
        </p>
        <p className="mb-3 text-justify">
          Sublimace využívá inkousty citlivé na teplotu. Na plotru (inkoustové
          tiskárně) se motiv vytiskne na speciální papír a po zaschnutí se v
          termotransferovém lisu zažehlí na polyesterovou tkaninu. Zahřátím se
          sublimační inkousty mění na plyn, zároveň se horkem otevřou póry v
          polyesterovém vláknu. Do těchto pórů proniká pigment z inkoustu a
          propojí se s polyesterovým vláknem. Ochlazením se póry opět uzavřou a
          barvivo je v materiálu trvanlivě zafixováno. Takto vyrobené motivy
          jsou extrémně odolné proti seprání a oděru, protože obraz není pouze
          na povrchu, ale barevné pigmenty prostoupí hluboko do struktury
          materiálu.
        </p>
        <p className="mb-3 text-justify">
          Tisk sublimací je plně digitální proces, který umožňuje natisknout
          jakýkoli motiv, včetně fotografií. Metodou sublimace je možné vyrobit
          oděvní etikety, etikety na lůžkoviny a matrace, etikety pro výrobky s
          následnými chemickými a mechanickými úpravami, reklamní stuhy a šerpy,
          lanyardy, potisk sportovních oděvů, atd.
        </p>
        <p className="mb-3 text-justify">
          Omezením metody je chemické složení potiskovaného materiálu -
          sublimace zaručuje výsleky pouze u polyesterových vláken.
        </p>
        <p>Na všechny materiály máme certifikát OEKO-TEX.</p>
      </div>
      <div className="relative z-0">
        <StaticImage src="../images/sublimace/bg.jpg" alt="Sublimace" />
        <div className="absolute left-10 top-20 z-20">
          <StaticImage
            src="../images/sublimace/surf-studio.png"
            alt="Sublimace"
            className="w-6/12 shadow mb-10"
          />
          <StaticImage
            src="../images/sublimace/show-jana-krause.png"
            alt="Sublimace"
            className="w-8/12 shadow"
          />
        </div>
        <div className="bg-gradient-to-b from-white via-transparent to-white h-100 inset-0 absolute" />
      </div>
      <div className="justify-center flex my-5">
        <table>
          <tr>
            <td className="font-bold pr-10">Minimální množství</td>
            <td>Velikost série bez omezení, není minimální množství</td>
          </tr>
          <tr>
            <td className="font-bold pr-10">
              Podkladový materiál pro etikety a stuhy
            </td>
            <td>
              polyesterový jemný satén (A5), šířky 15, 20, 25, 30, 35, 40, 45,
              50, 60, 70, 80, 100 mm, možnost potisku polyesterového popruhu
            </td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Podkladový materiál pro dresy</td>
            <td>donesený materiál, nastříhané díly max. 1100x700mm</td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Barvy</td>
            <td>
              Barva tisku libovolná v barevném prostoru CMYK (mimo metalických a
              neonových odstínů)
            </td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Výška etikety</td>
            <td>Libovolná od 25 mm</td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Cena</td>
            <td>
              Cena je závislá na velikosti etikety a objednaném množství,
              barevnost a změny motivu nemají na cenu vliv.
            </td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Dodací lhůta</td>
            <td>cca 2-3 týdny od potvrzení objednávky</td>
          </tr>
        </table>
      </div>
      <div className="flex justify-center mb-5">
        <StaticImage
          src="../images/sublimace/sublimace-footer.png"
          alt="Sublimace"
        />
      </div>
      <p>
        Poptávky a jakékoli dotazy či připomínky ohledně sublimace zasílejte na:{" "}
        <a className="font-bold" href="mailto:sublimace@etka.cz">
          sublimace@etka.cz
        </a>
      </p>
    </Layout>
  );
};

export default SublimacePage;
